import React from 'react'
import SeoLandingpage from '@loggedOut/SeoLandingpage'
import { graphql } from 'gatsby'
import Header from "@menus/header"
import {useObjectifyStoryArray} from '@hooks/useObjectifyStoryArray'

const SeoLandingpageTemplate = (props) => {
  const storiesObject = useObjectifyStoryArray(props.data.allStory)
  const { 
      heroTitle, heroText, firstTitle, firstText, firstStory, secondTitle, secondText, 
      faqContent, seoTitle, seoDescription, firstCtaText, secondCtaText, firstSlider, videoUrl
    } = props.pageContext.landingpage[props.pageContext.lang]
 
  
  return (
    <>
      <Header location={props.location}/> 
      <SeoLandingpage
        heroTitle={heroTitle}
        heroText={heroText}
        firstTitle={firstTitle}
        firstText={firstText}
        firstStory={firstStory}
        secondTitle={secondTitle}
        secondText={secondText}
        videoUrl={videoUrl}
        FAQContent={faqContent}
        SEOTitle={seoTitle}
        SEODescription={seoDescription}
        CTAText={firstCtaText}
        CTATextSecond={secondCtaText}
        firstSlider={firstSlider}
        stories={storiesObject} 
        sliderContent={props.pageContext.sliderContent}
      />
    </>
  );
}

// load only stories which are displayed at page
export const query = graphql`
  query LandingPageStoryQuery($storyQueryIds:[String!],$isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!) {
    allStory(filter: {id:{ in: $storyQueryIds  }}) {
      edges {
        node {
          ...allStoryInfo
        }
      }
    }
    dummyToUseVarInFragment: allStory(filter: {id: {eq: "nevertrue"}}){
      edges {
        node{
          id @include(if: $isEs)
          id @include(if: $isEn)
          id @include(if: $isDe)
        }
      }
    }
  }
`;  

export default SeoLandingpageTemplate